import { Box, Typography } from "@mui/material";

type Props = {
    title: string;
    subtitle?: string;
};

const Header = ({ title, subtitle }: Props) => {
    return (
        <Box sx={{ mb: 2 }}>
            <Typography variant="h5">{title}</Typography>
            {subtitle && <Typography variant="subtitle2">{subtitle}</Typography>}
        </Box>
    );
};

export default Header;
