import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en.json";
import { ThemeProvider } from "@mui/system";
import theme from "./theme/theme";
import { CssBaseline } from "@mui/material";
import { HashRouter as Router } from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { Toaster } from "react-hot-toast";

TimeAgo.addDefaultLocale(en as any);

ReactDOM.render(
    <React.StrictMode>
        <LocalizationProvider dateAdapter={AdapterLuxon}>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <Router>
                    <App />
                </Router>
                <Toaster />
            </ThemeProvider>
        </LocalizationProvider>
    </React.StrictMode>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
