import { Navigate, Outlet, useLocation } from "react-router-dom";
import AuthStore from "../../stores/AuthStore";
import { Box } from "@mui/material";
import Header from "../../components/Header";

const AdminWrapper = () => {
    const location = useLocation();

    if (!AuthStore.loggedIn || !AuthStore.hasAdmin) {
        return <Navigate replace to="/" />;
    }

    return (
        <Box>
            <Header title="Admin" />
            <Outlet />
        </Box>
    );
};

export default AdminWrapper;
