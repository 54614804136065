import {
    Button,
    Card,
    CardContent,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    LinearProgress,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import RefreshIcon from "@mui/icons-material/Refresh";
import { observer } from "mobx-react";
import React from "react";
import Fetch from "../api/Fetch";
import { BACKEND_URI } from "../constants";
import Toaster from "../common/Toaster";
import { GuestList, Location } from "../model/Types";

const GuestListView = observer(() => {
    const [loading, setLoading] = React.useState(true);
    const [guestLists, setGuestLists] = React.useState<any[]>([]);
    const [id, setId] = React.useState<number | undefined>(undefined);
    const [locations, setLocations] = React.useState<Location[]>([]);
    const [selectedLocation, setSelectedLocation] = React.useState<number | undefined>(undefined);

    const cb = React.useCallback(async () => {
        setLoading(true);
        try {
            const gl = await Fetch<GuestList[]>(`${BACKEND_URI}/guest-list`, {
                includeAuth: true,
            });
            setGuestLists(gl);
            const l = await Fetch<Location[]>(`${BACKEND_URI}/locations`, {
                includeAuth: true,
            });
            setLocations(l.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase())));
            setSelectedLocation(l.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))[0]?.id);
        } catch (err: any) {
            console.log(err);
            Toaster.error(err.message || err);
        } finally {
            setTimeout(() => {
                setLoading(false);
            }, 500);
        }
    }, []);

    React.useEffect(() => {
        cb();
    }, [cb]);

    const logGuest = async (e: React.FormEvent<any>) => {
        e.preventDefault();
        setLoading(true);
        try {
            await Fetch(`${BACKEND_URI}/guest-list-guest`, {
                includeAuth: true,
                body: {
                    id,
                    locationId: selectedLocation,
                },
                isText: true,
                method: "POST",
            });
            setId(undefined);
            setSelectedLocation(locations[0]?.id);
            cb();
        } catch (err: any) {
            console.log(err);
            Toaster.error(err.message || err);
        } finally {
            setTimeout(() => {
                setLoading(false);
            }, 500);
        }
    };

    return (
        <Box sx={{ marginBottom: "20px" }}>
            <Card>
                <CardContent>
                    <Button sx={{ float: "right" }} onClick={() => cb()} variant="contained">
                        <RefreshIcon /> Refresh
                    </Button>
                    <Table>
                        <TableBody>
                            {loading ? (
                                <TableRow>
                                    <TableCell colSpan={2}>
                                        <LinearProgress />
                                    </TableCell>
                                </TableRow>
                            ) : guestLists.length > 0 ? (
                                <>
                                    {guestLists.map((gl) => (
                                        <>
                                            <TableRow>
                                                <TableCell colSpan={2}>
                                                    <Typography variant="h5">{gl.title}</Typography>
                                                </TableCell>
                                            </TableRow>
                                            {gl.guests.map((g) => (
                                                <TableRow>
                                                    <TableCell>
                                                        {g.fulfilledByUser ? (
                                                            <Typography sx={{ textDecoration: "line-through", paddingLeft: "25px" }} variant="h6">
                                                                {g.name}
                                                            </Typography>
                                                        ) : (
                                                            <Typography sx={{ paddingLeft: "25px" }} variant="h6">
                                                                {g.name}
                                                            </Typography>
                                                        )}
                                                    </TableCell>
                                                    <TableCell sx={{ textAlign: "right" }}>
                                                        {!g.location ? (
                                                            <Button
                                                                onClick={() => {
                                                                    setId(g.id);
                                                                }}
                                                                variant="contained"
                                                            >
                                                                Log Guest
                                                            </Button>
                                                        ) : (
                                                            <Typography>Logged at {g.location.name}</Typography>
                                                        )}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </>
                                    ))}
                                </>
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={2} sx={{ textAlign: "center" }}>
                                        No guest lists available today.
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </CardContent>
            </Card>
            <Dialog open={!!id} onClose={() => setId(undefined)} fullWidth>
                <form onSubmit={logGuest}>
                    <DialogTitle>Log Guest</DialogTitle>
                    <DialogContent>
                        <TextField
                            required
                            label="Location"
                            onChange={(e) => setSelectedLocation(parseInt(e.target.value, 10))}
                            value={selectedLocation}
                            fullWidth
                            select
                            sx={{ mt: 1 }}
                        >
                            {locations.map((l) => (
                                <MenuItem key={l.id} value={l.id}>
                                    {l.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" onClick={() => setId(undefined)} type="button">
                            Cancel
                        </Button>
                        <Button variant="contained" color="secondary" type="submit">
                            Log
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </Box>
    );
});

export default GuestListView;
