import { Button, Dialog, DialogContent, DialogTitle, Table, TableBody, TableCell, TableHead, TableRow, TextField } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { BACKEND_URI } from "../../constants";
import { DailyInfo, DailyTask } from "../../model/Types";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DateTime } from "luxon";
import AuthStore from "../../stores/AuthStore";
import Fetch from "../../api/Fetch";

const AdminDailyTask = () => {
    const [dailyInfo, setDailyInfo] = React.useState<DailyTask[]>([]);
    const [open, setOpen] = React.useState<boolean>(false);
    const [currentInfo, setCurrentInfo] = React.useState<any>(undefined);

    const cb = React.useCallback(async () => {
        try {
            const body = await Fetch<DailyTask[]>(`${BACKEND_URI}/admin/daily-task`, {
                includeAuth: true,
            });
            setDailyInfo(body);
        } catch (err) {
            console.log(err);
        }
    }, []);

    React.useEffect(() => {
        cb();
    }, [cb]);

    const onChangeTask = (task: string) => {
        setCurrentInfo({
            ...currentInfo,
            task,
        });
    };

    const onChangeWeight = (weight: any) => {
        setCurrentInfo({
            ...currentInfo,
            weight: weight ? parseInt(weight, 10) : undefined,
        });
    };

    const dateOnChange = (date?: any) => {
        if (date) {
            setCurrentInfo({
                ...currentInfo,
                date: date.toString(),
            });
        }
    };

    const onSave = async (e: any) => {
        try {
            const body = await Fetch<DailyTask>(`${BACKEND_URI}/admin/daily-task`, {
                method: "POST",
                includeAuth: true,
                body: currentInfo,
            });
            setDailyInfo([...dailyInfo.filter((it) => it.id !== body.id), body]);
            setOpen(false);
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <Box>
            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
                <Button
                    variant="contained"
                    onClick={() => {
                        setCurrentInfo({
                            date: new Date().toISOString(),
                            task: "",
                        });
                        setOpen(true);
                    }}
                >
                    Create
                </Button>
            </Box>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Date</TableCell>
                        <TableCell width="195px" />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {dailyInfo
                        .sort((a, b) => Date.parse(a.date) - Date.parse(b.date))
                        .map((it) => (
                            <TableRow>
                                <TableCell>{it.date.substring(0, 10)}</TableCell>
                                <TableCell>
                                    <Button
                                        variant="contained"
                                        sx={{ marginRight: "8px" }}
                                        onClick={() => {
                                            setCurrentInfo(it);
                                            setOpen(true);
                                        }}
                                    >
                                        Edit
                                    </Button>
                                    <Button variant="contained" color="secondary" onClick={() => alert("TODO: Add delete for daily task.")}>
                                        Delete
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                </TableBody>
            </Table>
            <Dialog open={open} maxWidth="lg" fullWidth onClose={() => setOpen(false)}>
                <DialogTitle>
                    {currentInfo?.id ? "Edit" : "Create"} Daily Task for {(currentInfo?.date ?? "").substring(0, 10)}
                </DialogTitle>
                <DialogContent>
                    <Box sx={{ margin: "10px" }}>
                        <DatePicker
                            label="Date"
                            onChange={dateOnChange}
                            renderInput={(params) => <TextField {...params} />}
                            value={currentInfo?.date ?? "2000-01-01"}
                        />
                        <Box sx={{ margin: "10px" }} />
                        <TextField type="number" value={currentInfo?.weight ?? ""} onChange={(e) => onChangeWeight(e.target.value)} label="Weight" />
                        <Box sx={{ margin: "10px" }} />
                        <TextField
                            onChange={(e) => onChangeTask(e.target.value)}
                            value={currentInfo?.task ?? ""}
                            variant="outlined"
                            fullWidth
                            label="Task Name"
                        />
                        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", marginTop: "8px" }}>
                            <Button variant="contained" color="secondary" sx={{ marginRight: "8px" }} onClick={() => setOpen(false)}>
                                Cancel
                            </Button>
                            <Button variant="contained" onClick={onSave}>
                                Save
                            </Button>
                        </Box>
                    </Box>
                </DialogContent>
            </Dialog>
        </Box>
    );
};

export default AdminDailyTask;
