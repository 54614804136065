import { DateTime } from "luxon";
import React from "react";
import {
    Box,
    Button,
    Card,
    CardContent,
    FormLabel,
    Grid,
    IconButton,
    MenuItem,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableFooter,
    TableHead,
    TableRow,
    TextField,
    Typography,
    useTheme,
} from "@mui/material";
import { Location, Ticket } from "../model/Types";
import Fetch from "../api/Fetch";
import { BACKEND_URI } from "../constants";
import { DateTimePicker } from "@mui/x-date-pickers";
import SignatureCanvas from "react-signature-canvas";
import Webcam from "react-webcam";
import { CurrencyFormatter, dataURItoBlob } from "../common/Helpers";
import Toaster from "../common/Toaster";
import DeleteIcon from "@mui/icons-material/Delete";

const RefundView = () => {
    const canvasRef = React.useRef<SignatureCanvas>(null);
    const webcamRef = React.useRef<Webcam>(null);
    const theme = useTheme();
    const [loading, setLoading] = React.useState<boolean>(false);

    const [request, setRequest] = React.useState<{
        reason: string;
        guestName: string;
        guestNumber: string;
        timeOfSale: DateTime;
        timeOfRefund: DateTime;
        guestSignature: any | null;
        ticketImage: any | null;
        tickets: {
            ticketId: number;
            quantity: number;
        }[];
        locationId: number | null;
    }>({
        reason: "",
        guestName: "",
        guestNumber: "",
        timeOfSale: DateTime.now(),
        timeOfRefund: DateTime.now(),
        guestSignature: null,
        ticketImage: null,
        tickets: [],
        locationId: null,
    });

    const [locations, setLocations] = React.useState<Location[]>([]);
    const [tickets, setTickets] = React.useState<Ticket[]>([]);

    const [ticketRequest, setTicketRequest] = React.useState<{
        ticketId: number;
        quantity: number;
    }>({
        ticketId: 0,
        quantity: 1,
    });

    const onSubmit = async () => {
        setLoading(true);
        try {
            const img = canvasRef.current?.getTrimmedCanvas().toDataURL("image/png");
            const res = await Fetch<string>(`${BACKEND_URI}/refund`, {
                method: "POST",
                includeAuth: true,
                body: {
                    ...request,
                    guestSignature: img,
                },
                isText: true,
            });
            Toaster.success(res);
            setRequest({
                reason: "",
                guestName: "",
                guestNumber: "",
                timeOfSale: DateTime.now(),
                timeOfRefund: DateTime.now(),
                guestSignature: null,
                ticketImage: null,
                tickets: [],
                locationId: null,
            });
            canvasRef.current?.clear();
            webcamRef.current?.video?.play();
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    const cb = React.useCallback(async () => {
        try {
            const l = await Fetch<Location[]>(`${BACKEND_URI}/locations`, {
                includeAuth: true,
            });
            setLocations(l.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase())));
            setRequest({
                ...request,
                locationId: null,
            });
            const t = await Fetch<Ticket[]>(`${BACKEND_URI}/tickets`, {
                includeAuth: true,
            });
            setTickets(t.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase())));
        } catch (e) {
            console.error(e);
        }
    }, []);

    React.useEffect(() => {
        cb();
    }, [cb]);

    return (
        <Box>
            <Typography variant="h5">Issue Refund</Typography>
            <Card sx={{ mt: 1 }}>
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                            <TextField
                                size="small"
                                fullWidth
                                label="Gate"
                                select
                                value={request.locationId}
                                onChange={(e) => {
                                    setRequest({
                                        ...request,
                                        locationId: parseInt(e.target.value, 10),
                                    });
                                }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            >
                                {locations.map((l) => (
                                    <MenuItem key={l.id} value={l.id}>
                                        {l.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <TextField
                                size="small"
                                fullWidth
                                label="Guest Name"
                                value={request.guestName}
                                onChange={(e) => {
                                    setRequest({
                                        ...request,
                                        guestName: e.target.value,
                                    });
                                }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <TextField
                                size="small"
                                fullWidth
                                label="Guest Number"
                                value={request.guestNumber}
                                onChange={(e) => {
                                    setRequest({
                                        ...request,
                                        guestNumber: e.target.value,
                                    });
                                }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <DateTimePicker
                                label="Time of Ticket Sale"
                                value={request.timeOfSale}
                                onChange={(e) => {
                                    setRequest({
                                        ...request,
                                        timeOfSale: e ?? DateTime.now(),
                                    });
                                }}
                                renderInput={(props) => (
                                    <TextField
                                        {...props}
                                        size="small"
                                        fullWidth
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <DateTimePicker
                                label="Time of Refund"
                                value={request.timeOfRefund}
                                onChange={(e) => {
                                    setRequest({
                                        ...request,
                                        timeOfRefund: e ?? DateTime.now(),
                                    });
                                }}
                                renderInput={(props) => (
                                    <TextField
                                        {...props}
                                        size="small"
                                        fullWidth
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                size="small"
                                fullWidth
                                label="Reason for Refund"
                                value={request.reason}
                                rows={4}
                                multiline
                                onChange={(e) => {
                                    setRequest({
                                        ...request,
                                        reason: e.target.value,
                                    });
                                }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Card variant="outlined">
                                <CardContent>
                                    <Typography variant="h6" sx={{ mb: 1 }}>
                                        Tickets to Refund
                                    </Typography>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={4}>
                                            <TextField
                                                select
                                                fullWidth
                                                size="small"
                                                label="Select Ticket"
                                                value={ticketRequest.ticketId}
                                                onChange={(e) => {
                                                    setTicketRequest({
                                                        ...ticketRequest,
                                                        ticketId: parseInt(e.target.value, 10),
                                                    });
                                                }}
                                            >
                                                {tickets
                                                    .filter((t) => !request.tickets.find((ti) => ti.ticketId === t.id))
                                                    .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
                                                    .map((t) => (
                                                        <MenuItem value={t.id}>
                                                            {t.name} - {CurrencyFormatter.format(t.price)}
                                                        </MenuItem>
                                                    ))}
                                            </TextField>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <TextField
                                                size="small"
                                                fullWidth
                                                label="Quantity"
                                                type="number"
                                                value={ticketRequest.quantity}
                                                onChange={(e) => {
                                                    setTicketRequest({
                                                        ...ticketRequest,
                                                        quantity: parseInt(e.target.value, 10),
                                                    });
                                                }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <Button
                                                variant="contained"
                                                fullWidth
                                                disabled={!ticketRequest.ticketId || !ticketRequest.quantity}
                                                onClick={() => {
                                                    setRequest({
                                                        ...request,
                                                        tickets: [
                                                            ...request.tickets,
                                                            {
                                                                ticketId: ticketRequest.ticketId,
                                                                quantity: ticketRequest.quantity,
                                                            },
                                                        ],
                                                    });
                                                    setTicketRequest({
                                                        ticketId: 0,
                                                        quantity: 1,
                                                    });
                                                }}
                                            >
                                                Add Ticket
                                            </Button>
                                        </Grid>
                                    </Grid>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Ticket</TableCell>
                                                <TableCell>Price</TableCell>
                                                <TableCell>Quantity</TableCell>
                                                <TableCell>Total</TableCell>
                                                <TableCell width="75px"></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {request.tickets.map((t) => {
                                                const ticket = tickets.find((ti) => ti.id === t.ticketId);
                                                if (!ticket) {
                                                    return null;
                                                }
                                                return (
                                                    <TableRow key={ticket.id}>
                                                        <TableCell>{ticket.name}</TableCell>
                                                        <TableCell>{CurrencyFormatter.format(ticket.price)}</TableCell>
                                                        <TableCell>{t.quantity}</TableCell>
                                                        <TableCell>{CurrencyFormatter.format(ticket.price * t.quantity)}</TableCell>
                                                        <TableCell>
                                                            <IconButton
                                                                onClick={() => {
                                                                    setRequest({
                                                                        ...request,
                                                                        tickets: request.tickets.filter((ti) => ti !== t),
                                                                    });
                                                                }}
                                                            >
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                        <TableFooter>
                                            <TableRow>
                                                <TableCell colSpan={3}>Total</TableCell>
                                                <TableCell>
                                                    {CurrencyFormatter.format(
                                                        request.tickets.reduce(
                                                            (acc, t) => acc + (tickets.find((ti) => ti.id === t.ticketId)?.price ?? 0) * t.quantity,
                                                            0
                                                        )
                                                    )}
                                                </TableCell>
                                                <TableCell />
                                            </TableRow>
                                        </TableFooter>
                                    </Table>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
                            <Box sx={{ display: "flex", flexDirection: "column", maxWidth: "100%", width: 500 }}>
                                <FormLabel>Guest Signature</FormLabel>
                                <SignatureCanvas
                                    canvasProps={{
                                        style: {
                                            backgroundColor: theme.palette.background.default,
                                        },
                                        width: 500,
                                        height: 150,
                                    }}
                                    penColor="white"
                                    ref={canvasRef}
                                />
                                <Button onClick={() => canvasRef.current?.clear()} color="error">
                                    Clear
                                </Button>
                            </Box>
                        </Grid>
                        {/* <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
                            <Box sx={{ display: "flex", flexDirection: "column", maxWidth: "100%", width: 500 }}>
                                <FormLabel>Photo of Tickets</FormLabel>
                                <Webcam
                                    videoConstraints={{
                                        facingMode: "environment",
                                    }}
                                    style={{
                                        maxWidth: 500,
                                        width: "100%",
                                    }}
                                    ref={webcamRef}
                                />
                                {!request.ticketImage && (
                                    <Button
                                        onClick={() => {
                                            webcamRef.current?.video?.pause();
                                            const ss = webcamRef.current?.getScreenshot();
                                            if (ss) {
                                                setRequest({
                                                    ...request,
                                                    ticketImage: ss,
                                                });
                                            }
                                        }}
                                        color="success"
                                    >
                                        Take photo
                                    </Button>
                                )}
                                {request.ticketImage && (
                                    <Button
                                        onClick={() => {
                                            webcamRef.current?.video?.play();
                                            setRequest({
                                                ...request,
                                                ticketImage: null,
                                            });
                                        }}
                                        color="error"
                                    >
                                        Clear
                                    </Button>
                                )}
                            </Box>
                        </Grid> */}
                        <Grid item xs={12}>
                            <Button
                                variant="contained"
                                fullWidth
                                onClick={onSubmit}
                                disabled={
                                    !request.locationId ||
                                    !request.guestName ||
                                    !request.guestNumber ||
                                    !request.timeOfSale ||
                                    !request.timeOfRefund ||
                                    !request.reason ||
                                    request.tickets.length === 0 ||
                                    loading
                                }
                            >
                                Issue Refund
                            </Button>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Box>
    );
};

export default RefundView;
