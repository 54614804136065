import { Button, TextareaAutosize, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import Fetch from "../api/Fetch";
import { BACKEND_URI } from "../constants";
import Toaster from "../common/Toaster";

const NoteView = () => {
    const [loading, setLoading] = React.useState<boolean>(false);
    const [request, setRequest] = React.useState({ title: "", message: "" });

    const onSubmit = async (e: any) => {
        e.preventDefault();
        if (!request.title || !request.message) {
            Toaster.error("Must fill out both fields before sending.");
            return;
        }
        setLoading(true);
        try {
            await Fetch(`${BACKEND_URI}/notes`, {
                method: "POST",
                includeAuth: true,
                body: request,
                isText: true,
            });
            setRequest({ title: "", message: "" });
            Toaster.success("Note sent successfully.");
        } catch (err: any) {
            console.log(err);
            Toaster.error(err.message ?? "An error occurred while sending note.");
        } finally {
            setLoading(false);
        }
    };

    return (
        <Box sx={{ display: "flex", flexDirection: "column" }} component="form" onSubmit={onSubmit}>
            <Typography variant="h4" sx={{ marginBottom: "20px" }}>
                Notes
            </Typography>
            <Box sx={{ marginBottom: "10px" }}>
                <TextField
                    label="Title"
                    disabled={loading}
                    value={request.title}
                    onChange={(e) => setRequest({ ...request, title: e.target.value })}
                />
            </Box>
            <TextField
                label="Note"
                multiline
                minRows={8}
                sx={{ marginBottom: "10px" }}
                disabled={loading}
                value={request.message}
                onChange={(e) => setRequest({ ...request, message: e.target.value })}
            />
            <Button variant="contained" type="submit" disabled={loading}>
                Send
            </Button>
        </Box>
    );
};

export default NoteView;
