import { Box } from "@mui/material";
import "./App.css";
import { observer } from "mobx-react";
import AuthStore from "./stores/AuthStore";
import Login from "./views/Login";
import LoggedIn from "./views/LoggedIn";

const App = observer(() => {
    // const [progressValue, setProgressValue] = React.useState(100);

    // React.useEffect(() => {
    //     const interval = setInterval(() => {
    //         setProgressValue((oldProgressValue) => oldProgressValue - 1.15);
    //         if (progressValue === 0 && interval) {
    //             clearTimeout(interval);
    //         }
    //     }, 100);
    //     return () => clearInterval(interval);
    // }, []);

    return <Box sx={{ display: "flex", height: "100vh" }}>{AuthStore.loggedIn ? <LoggedIn /> : <Login />}</Box>;
});

export default App;
