import {
    Box,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableFooter,
    TableHead,
    TableRow,
    TextField,
} from "@mui/material";
import { observer } from "mobx-react";
import React from "react";
import Fetch from "../../api/Fetch";
import { BACKEND_URI } from "../../constants";
import EventStore from "../../stores/EventStore";
import { Location, Refund } from "../../model/Types";
import Toaster from "../../common/Toaster";
import { DatePicker } from "@mui/x-date-pickers";
import { DateTime } from "luxon";
import { CurrencyFormatter } from "../../common/Helpers";
import ExcelJS from "exceljs";
import saveAs from "file-saver";

const CURRENCY_FORMAT = '_($* #,##0.00_);_($* (#,##0.00);_($* ""-""??_);_(@_)';

const AdminEventRefunds = observer(() => {
    const [refunds, setRefunds] = React.useState<Refund[]>([]);
    const [date, setDate] = React.useState<string>(DateTime.now().toFormat("yyyy-MM-dd"));
    const [loading, setLoading] = React.useState<boolean>(true);

    async function getRefunds() {
        setLoading(true);
        setRefunds([]);
        try {
            const data = await Fetch<Refund[]>(`${BACKEND_URI}/admin/event/${EventStore.eventId}/refunds?date=${date}`, {
                includeAuth: true,
            });
            setRefunds(data);
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    }

    function exportExcel() {
        const deptQuantities = refunds.reduce(
            (a, b) => {
                b.items.forEach((it) => {
                    if (a[it.ticket.name]) {
                        a[it.ticket.name].quantity += it.quantity;
                    } else {
                        a[it.ticket.name] = {
                            quantity: it.quantity,
                            price: it.ticket.price,
                        };
                    }
                });
                return a;
            },
            {} as {
                [key: string]: {
                    quantity: number;
                    price: number;
                };
            }
        );

        const workbook = new ExcelJS.Workbook();

        let rowIdx = 1;

        workbook.creator = "AuthentiGATE Reporting";
        workbook.lastModifiedBy = "AuthentiGATE Reporting";
        workbook.created = new Date();
        workbook.modified = new Date();
        workbook.lastPrinted = new Date();
        workbook.calcProperties.fullCalcOnLoad = false;

        const worksheet = workbook.addWorksheet("Refunds");
        const columns = ["Gate", "Refund Description", "Authorized By", "Ticket Department(s)", "Total Amount", "Guest #"];

        let row = worksheet.getRow(rowIdx);
        let cell = row.getCell(1);
        cell.value = EventStore.event?.name;
        cell.font = { bold: true };
        rowIdx++;
        row = worksheet.getRow(rowIdx);
        cell = row.getCell(1);
        cell.value = `Gate Refund Daily Report`;
        cell.font = { bold: true };
        rowIdx++;
        row = worksheet.getRow(rowIdx);
        cell = row.getCell(1);
        cell.value = date.substring(0, 10);
        cell.font = { bold: true };
        rowIdx++;
        row = worksheet.getRow(rowIdx);
        cell = row.getCell(1);
        cell.value = `Day ${DateTime.fromISO(date).diff(DateTime.fromFormat(EventStore.event!.eventStart, "yyyy-MM-dd"), "days").days + 1}`;
        cell.font = { bold: true };
        rowIdx += 2;

        row = worksheet.getRow(rowIdx);
        columns.forEach((c, idx) => {
            cell = row.getCell(idx + 1);
            cell.value = c;
            cell.font = { bold: true };
        });
        rowIdx++;

        refunds.forEach((refund) => {
            row = worksheet.getRow(rowIdx);
            cell = row.getCell(1);
            cell.value = refund.location.name;
            cell = row.getCell(2);
            cell.value = refund.reason;
            cell = row.getCell(3);
            cell.value = `${refund.user.firstName} ${refund.user.lastName}`;
            cell = row.getCell(4);
            cell.value = refund.items.map((it) => `${it.quantity}x ${it.ticket.name}`).join(", ");
            cell = row.getCell(5);
            cell.value = refund.items.reduce((a, b) => a + b.quantity * b.ticket.price, 0);
            cell.numFmt = CURRENCY_FORMAT;
            cell = row.getCell(6);
            cell.value = refund.guestNumber;
            rowIdx++;
        });

        row = worksheet.getRow(rowIdx);
        cell = row.getCell(1);
        cell.value = "Total";
        cell.font = { bold: true };
        cell = row.getCell(5);
        cell.value = refunds.reduce((a, b) => a + b.items.reduce((c, d) => c + d.quantity * d.ticket.price, 0), 0);
        cell.font = { bold: true };
        cell.numFmt = CURRENCY_FORMAT;
        rowIdx++;

        rowIdx += 4;

        row = worksheet.getRow(rowIdx);
        cell = row.getCell(3);
        cell.value = "Quantity";
        cell.font = { bold: true };
        cell = row.getCell(4);
        cell.value = "Ticket Department";
        cell.font = { bold: true };
        cell = row.getCell(5);
        cell.value = "Total Amount";
        cell.font = { bold: true };
        rowIdx++;
        Object.entries(deptQuantities)
            .sort(([a1, a2], [b1, b2]) => a1.toLowerCase().localeCompare(b1.toLowerCase()))
            .forEach(([key, value]) => {
                row = worksheet.getRow(rowIdx);
                cell = row.getCell(3);
                cell.value = value.quantity;
                cell = row.getCell(4);
                cell.value = key;
                cell = row.getCell(5);
                cell.value = value.quantity * value.price;
                cell.numFmt = CURRENCY_FORMAT;
                rowIdx++;
            });

        worksheet.columns.forEach((column) => {
            let maxLength = 0;
            column?.eachCell?.({ includeEmpty: true }, (cell) => {
                const columnLength = cell.value ? cell.value.toString().length : 13;
                if (columnLength > maxLength) {
                    maxLength = columnLength;
                }
            });
            column.width = maxLength < 10 ? 10 : maxLength;
        });

        workbook.xlsx.writeBuffer().then((data) => {
            saveAs(new Blob([data]), `${EventStore.event?.name} Refunds ${date.substring(0, 10)}.xlsx`);
        });
    }

    React.useEffect(() => {
        getRefunds();
    }, [date]);

    return (
        <Box sx={{ mt: 2 }}>
            <DatePicker
                onChange={(d) => {
                    if (d) {
                        setDate(d.toFormat("yyyy-MM-dd"));
                    }
                }}
                value={DateTime.fromFormat(date, "yyyy-MM-dd")}
                renderInput={(props) => <TextField fullWidth {...props} size="small" />}
            />
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell></TableCell>
                        <TableCell>Time Refunded</TableCell>
                        <TableCell>Location</TableCell>
                        <TableCell>Refunded By</TableCell>
                        <TableCell>Guest Name</TableCell>
                        <TableCell>Guest Number</TableCell>
                        <TableCell>Reason</TableCell>
                        <TableCell sx={{ textAlign: "right" }}>Total Refunded</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {loading && (
                        <TableRow>
                            <TableCell colSpan={8} align="center">
                                Loading...
                            </TableCell>
                        </TableRow>
                    )}
                    {!loading &&
                        refunds
                            .sort((a, b) => a.location.name.toLowerCase().localeCompare(b.location.name.toLowerCase()))
                            .map((it) => (
                                <TableRow component="tr" hover>
                                    <TableCell></TableCell>
                                    <TableCell>{DateTime.fromISO(it.timeOfRefund).toFormat("yyyy-MM-dd HH:mm:ss")}</TableCell>
                                    <TableCell>{it.location.name}</TableCell>
                                    <TableCell>
                                        {it.user.firstName} {it.user.lastName}
                                    </TableCell>
                                    <TableCell>{it.guestName}</TableCell>
                                    <TableCell>{it.guestNumber}</TableCell>
                                    <TableCell>{it.reason}</TableCell>
                                    <TableCell sx={{ textAlign: "right" }}>
                                        {CurrencyFormatter.format(it.items.reduce((a, b) => a + b.quantity * b.ticket.price, 0))}
                                    </TableCell>
                                </TableRow>
                            ))}
                    {!loading && refunds.length === 0 && (
                        <TableRow>
                            <TableCell colSpan={8} align="center">
                                No refunds for this date
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TableCell />
                        <TableCell>Total</TableCell>
                        <TableCell colSpan={5} />
                        <TableCell sx={{ textAlign: "right" }}>
                            {CurrencyFormatter.format(refunds.reduce((a, b) => a + b.items.reduce((c, d) => c + d.quantity * d.ticket.price, 0), 0))}
                        </TableCell>
                    </TableRow>
                </TableFooter>
            </Table>
            <Box sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}>
                <Button variant="contained" onClick={exportExcel}>
                    Export to Excel
                </Button>
            </Box>
        </Box>
    );
});

export default AdminEventRefunds;
