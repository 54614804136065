import { Box, Button, CircularProgress, Table, TableBody, TableCell, TableHead, TableRow, TextField } from "@mui/material";
import { DateTime } from "luxon";
import { observer } from "mobx-react";
import React from "react";
import Fetch from "../api/Fetch";
import { BACKEND_URI, T1_BACKEND_URI } from "../constants";
import { Order, OrderStatus, OrderStatusFriendlyName } from "../model/Order";
import AuthStore from "../stores/AuthStore";
import Toaster from "../common/Toaster";

const OrderLookup = observer(() => {
    const [search, setSearch] = React.useState("");
    const [orders, setOrders] = React.useState<Order[]>([]);
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        getOrderList();
    }, []);

    const getOrderList = async () => {
        setLoading(true);
        try {
            const data = await Fetch<Order[]>(`${T1_BACKEND_URI}/lookup?searchText=${search}`, {
                includeAuth: true,
            });
            setOrders(data);
        } catch (err: any) {
            console.log(err);
            Toaster.error(err.message || "An error occurred");
        } finally {
            setTimeout(() => {
                setLoading(false);
            }, 500);
        }
    };

    const onSubmit = (e: any) => {
        e.preventDefault();
        getOrderList();
    };

    return (
        <>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Box component="form" onSubmit={onSubmit} sx={{ marginBottom: "20px" }}>
                    <TextField
                        size="small"
                        label="Search orders..."
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        sx={{ marginRight: "10px" }}
                    />
                    <Button variant="contained" type="submit">
                        Search
                    </Button>
                </Box>
                <Table sx={{ marginBottom: "20px" }}>
                    <TableHead sx={{ backgroundColor: "#002B3C" }}>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Date</TableCell>
                            <TableCell>Total</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {!loading ? (
                            <>
                                {orders.length === 0 && (
                                    <TableRow>
                                        <TableCell colSpan={6} sx={{ textAlign: "center" }}>
                                            No results.
                                        </TableCell>
                                    </TableRow>
                                )}
                                {orders.map((it) => (
                                    <TableRow
                                        key={it.id}
                                        sx={{
                                            backgroundColor:
                                                it.orderStatus === OrderStatus.PURCHASED
                                                    ? "#7dbf63"
                                                    : it.orderStatus === OrderStatus.REFUNDED
                                                    ? "#c94242"
                                                    : "#d0d46a",
                                            "&:hover": {
                                                cursor: it.orderStatus === OrderStatus.PURCHASED ? "pointer" : "",
                                                backgroundColor:
                                                    it.orderStatus === OrderStatus.PURCHASED
                                                        ? "#70bf50"
                                                        : it.orderStatus === OrderStatus.REFUNDED
                                                        ? "#cc3535"
                                                        : "#cacf57",
                                            },
                                        }}
                                        component="tr"
                                        onClick={() => {
                                            if (it.orderStatus !== OrderStatus.PURCHASED) return;
                                            window.open(`https://shop.authentigate.ca/store/${it.cartUrl}/thank-you/${it.retrievalKey}`, "_blank");
                                        }}
                                    >
                                        <TableCell sx={{ color: "black" }}>{it.id}</TableCell>
                                        <TableCell sx={{ color: "black" }}>{it.name}</TableCell>
                                        <TableCell sx={{ color: "black" }}>{it.email}</TableCell>
                                        <TableCell sx={{ color: "black", fontWeight: "bold" }}>{OrderStatusFriendlyName(it.orderStatus)}</TableCell>
                                        <TableCell sx={{ color: "black" }}>{DateTime.fromMillis(it.dateCreated).toFormat("ff")}</TableCell>
                                        <TableCell sx={{ color: "black" }}>${(it.total / 100).toFixed(2)}</TableCell>
                                    </TableRow>
                                ))}
                            </>
                        ) : (
                            <TableRow>
                                <TableCell colSpan={6} sx={{ textAlign: "center" }}>
                                    <CircularProgress />
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </Box>
        </>
    );
});

export default OrderLookup;
