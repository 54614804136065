import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

const videos = [
    {
        id: 1,
        label: "change-paper",
        text: "Change Printer Paper",
        html: "Change Printer Paper",
    },
    {
        id: 2,
        label: "change-battery",
        text: "Change Scanner Battery",
        html: "Change Scanner Battery",
    },
    {
        id: 3,
        label: "change-token-roll",
        text: "Change Token Roll",
        html: "Change Token Roll",
    },
];

const HowToVideosView = () => {
    const [expanded, setExpanded] = React.useState<string | false>("change-paper");

    const handleChange = (panel: string) => (event: any, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };
    return (
        <Box>
            {videos.map((it) => (
                <Accordion expanded={expanded === it.label} onChange={handleChange(it.label)}>
                    <AccordionSummary>{it.text}</AccordionSummary>
                    {expanded === it.label && (
                        <AccordionDetails key={it.id}>
                            <Typography variant="body2">{it.html}</Typography>
                        </AccordionDetails>
                    )}
                </Accordion>
            ))}
        </Box>
    );
};

export default HowToVideosView;
