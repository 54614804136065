import { makeAutoObservable } from "mobx";
import Fetch from "../api/Fetch";
import { EMS_BACKEND_URI } from "../constants";

const Now = () => new Date().getTime() / 1000;

class ScheduleStore {
    private _event?: any = undefined;
    private _eventId?: number = undefined;
    public loading: boolean = false;

    private timerRef: NodeJS.Timer | null = null;

    setLoading(l: boolean) {
        this.loading = l;
    }

    async eventOnChange(id: number) {
        try {
            this._eventId = id;
            const body = await Fetch(`${EMS_BACKEND_URI}/punch-clock/event/${id}?includeSchedule=true&includePunchClockState=true`, {
                includeAuth: true,
            });
            this._event = body;
        } catch (err) {
            console.log(err);
        } finally {
            setTimeout(() => {
                this.setLoading(false);
            }, 500);
        }
    }

    get event() {
        return this._event;
    }

    get eventId() {
        return this._eventId;
    }

    constructor() {
        makeAutoObservable(this);
    }
}

export default new ScheduleStore();
