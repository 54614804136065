import { Accordion, AccordionDetails, AccordionSummary, Card, CardContent, CircularProgress, Grid, LinearProgress, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { BACKEND_URI } from "../constants";
import { DailyInfo, Event, EventInfo, OperationInfo } from "../model/Types";
import AuthStore from "../stores/AuthStore";
import Fetch from "../api/Fetch";
import Toaster from "../common/Toaster";

const EventInfoView = () => {
    const [expanded, setExpanded] = React.useState<string | false>("today");
    const [dailyInfo, setDailyInfo] = React.useState<string | undefined>(undefined);
    const [eventInfo, setEventInfo] = React.useState<string | undefined>(undefined);
    const [operationInfo, setOperationInfo] = React.useState<string | undefined>(undefined);
    const [loading, setLoading] = React.useState<boolean>(true);

    const cb = React.useCallback(async () => {
        setLoading(true);
        try {
            const body = await Fetch<{
                dailyInfo: string;
                eventInfo: string;
                operationInfo: string;
            }>(`${BACKEND_URI}/event-daily-info`, {
                includeAuth: true,
            });
            setDailyInfo(body.dailyInfo);
            setEventInfo(body.eventInfo);
            setOperationInfo(body.operationInfo);
        } catch (err: any) {
            Toaster.error(err?.message ?? "An error occurred while retrieving current event.");
            console.log(err);
        } finally {
            setTimeout(() => {
                setLoading(false);
            }, 1_000);
        }
    }, []);

    React.useEffect(() => {
        cb();
    }, [cb]);

    const handleChange = (panel: string) => (event: any, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };
    return (
        <Box>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={9}>
                    <Accordion expanded={expanded === "today"} onChange={handleChange("today")}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography>Today's Information</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            {loading ? (
                                <LinearProgress />
                            ) : (
                                <div
                                    className="view ql-editor"
                                    dangerouslySetInnerHTML={{ __html: dailyInfo ?? "<div>No info for today!</div>" }}
                                    style={{ margin: "0px", padding: "0px" }}
                                />
                            )}
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === "event"} onChange={handleChange("event")}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography>Event Information</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            {loading ? (
                                <LinearProgress />
                            ) : (
                                <div
                                    className="view ql-editor"
                                    style={{ margin: "0px", padding: "0px" }}
                                    dangerouslySetInnerHTML={{ __html: eventInfo ?? "<div>No event information!</div>" }}
                                />
                            )}
                        </AccordionDetails>
                    </Accordion>
                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                    <Card>
                        <CardContent>
                            {loading ? (
                                <Box sx={{ textAlign: "center" }}>
                                    <CircularProgress />
                                </Box>
                            ) : (
                                <div
                                    className="view ql-editor"
                                    style={{ margin: "0px", padding: "0px" }}
                                    dangerouslySetInnerHTML={{ __html: operationInfo ?? "<div>No operation information!</div>" }}
                                />
                            )}
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    );
};

export default EventInfoView;
