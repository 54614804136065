import {
    Box,
    Button,
    Card,
    CardContent,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { DateTime } from "luxon";
import React from "react";
import Fetch from "../../api/Fetch";
import { BACKEND_URI } from "../../constants";
import { GuestList } from "../../model/Types";
import { observer } from "mobx-react";
import EventStore from "../../stores/EventStore";

const emptyRequest = () => ({
    id: undefined,
    title: "",
    date: DateTime.now().startOf("day").toUTC().startOf("day").toString(),
    guests: [],
});

const AdminGuestList = observer(() => {
    const [guestLists, setGuestLists] = React.useState<GuestList[]>([]);
    const [open, setOpen] = React.useState(false);
    const [currentInfo, setCurrentInfo] = React.useState<any>(emptyRequest());
    const [guestName, setGuestName] = React.useState("");
    const [selectedRow, setSelectedRow] = React.useState<string | undefined>(undefined);

    const guestNameRef = React.useRef<HTMLInputElement | null>(null);

    const cb = React.useCallback(async () => {
        try {
            const res = await Fetch<GuestList[]>(`${BACKEND_URI}/admin/event/${EventStore.eventId}/guest-list`, {
                includeAuth: true,
            });
            setGuestLists(res);
        } catch (err) {
            console.log(err);
        }
    }, []);

    React.useEffect(() => {
        cb();
    }, [cb]);

    const dateOnChange = (date?: any) => {
        if (date) {
            setCurrentInfo({
                ...currentInfo,
                date: date.toString(),
            });
        }
    };

    const onSave = async (e: any) => {
        if (currentInfo?.id) {
            try {
                await Fetch(`${BACKEND_URI}/admin/event/${EventStore.eventId}/guest-list`, {
                    method: "POST",
                    body: {
                        ...currentInfo,
                        guests: currentInfo.guests.map((it) => {
                            let id;
                            if (it.id > 0) id = it.id;
                            return {
                                ...it,
                                id,
                            };
                        }),
                    },
                    includeAuth: true,
                });
                cb();
                setOpen(false);
            } catch (err) {
                console.log(err);
            }
        } else {
            try {
                await Fetch(`${BACKEND_URI}/admin/event/${EventStore.eventId}/guest-list`, {
                    method: "POST",
                    body: {
                        ...currentInfo,
                        guests: currentInfo.guests.map((it) => {
                            let id: number | undefined;
                            if (it.id > 0) id = it.id;
                            return {
                                ...it,
                                id,
                            };
                        }),
                    },
                    includeAuth: true,
                });
                cb();
                setOpen(false);
            } catch (err) {
                console.log(err);
            }
        }
    };

    const getGuestId = (): number => {
        return Math.min(...currentInfo.guests.map((it) => it.id), 0) - 1;
    };

    return (
        <Box sx={{ mt: 2 }}>
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                    variant="contained"
                    onClick={() => {
                        setCurrentInfo(emptyRequest());
                        setOpen(true);
                    }}
                >
                    Create Guest List
                </Button>
            </Box>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>List Name</TableCell>
                        <TableCell>Date</TableCell>
                        <TableCell width="95px" />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {guestLists
                        ?.sort((a, b) => DateTime.fromISO(a.date).toMillis() - DateTime.fromISO(b.date).toMillis())
                        .map((it) => (
                            <TableRow
                                component="tr"
                                onMouseEnter={() => {
                                    setSelectedRow(it.date);
                                }}
                                onMouseLeave={() => {
                                    setSelectedRow(undefined);
                                }}
                                sx={{ backgroundColor: selectedRow === it.date ? "#012A3B" : "" }}
                            >
                                <TableCell>{it.title}</TableCell>
                                <TableCell>{DateTime.fromISO(it.date).toUTC().toFormat("LLL dd, yyyy")}</TableCell>
                                <TableCell>
                                    <Button
                                        variant="contained"
                                        onClick={() => {
                                            setOpen(true);
                                            setCurrentInfo({ ...it });
                                        }}
                                    >
                                        Edit
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                </TableBody>
            </Table>
            <Dialog open={open} maxWidth="lg" fullWidth onClose={() => setOpen(false)}>
                <DialogTitle>{currentInfo?.id ? "Edit" : "Create"} Guest List</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2} sx={{ marginTop: "5px" }}>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="List Name"
                                value={currentInfo.title}
                                onChange={(e) => {
                                    setCurrentInfo({
                                        ...currentInfo,
                                        title: e.target.value,
                                    });
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <DatePicker
                                label="Date"
                                onChange={dateOnChange}
                                renderInput={(params) => <TextField {...params} fullWidth />}
                                value={DateTime.fromISO(currentInfo.date).toUTC()}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Card>
                                <CardContent>
                                    <form
                                        onSubmit={(e) => {
                                            e.preventDefault();
                                            setCurrentInfo({
                                                ...currentInfo,
                                                guests: [...currentInfo.guests, { id: getGuestId(), name: guestName }],
                                            });
                                            setGuestName("");
                                            guestNameRef.current?.focus();
                                        }}
                                    >
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <Typography>Add New Guest</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    fullWidth
                                                    label="Guest Name"
                                                    value={guestName}
                                                    onChange={(e) => {
                                                        setGuestName(e.target.value);
                                                    }}
                                                    inputRef={(r) => (guestNameRef.current = r)}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Button fullWidth variant="contained" type="submit">
                                                    Add
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </form>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Guest Name</TableCell>
                                        <TableCell width="95px" />
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {currentInfo?.guests?.map((it) => (
                                        <TableRow>
                                            <TableCell>{it.name}</TableCell>
                                            <TableCell>
                                                <Button
                                                    variant="contained"
                                                    onClick={() => {
                                                        setCurrentInfo({
                                                            ...currentInfo,
                                                            guests: currentInfo.guests.filter((it2) => it2.id !== it.id),
                                                        });
                                                    }}
                                                >
                                                    Remove
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Grid>
                    </Grid>
                    <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", marginTop: "8px" }}>
                        <Button variant="contained" color="secondary" sx={{ marginRight: "8px" }} onClick={() => setOpen(false)}>
                            Cancel
                        </Button>
                        <Button variant="contained" onClick={onSave}>
                            Save
                        </Button>
                    </Box>
                </DialogContent>
            </Dialog>
        </Box>
    );
});

export default AdminGuestList;
