import { Box, Button, Drawer, IconButton, Typography } from "@mui/material";
import FlagIcon from "@mui/icons-material/Flag";
import SettingsIcon from "@mui/icons-material/Settings";
import ClockIcon from "@mui/icons-material/Event";
import MegaphoneIcon from "@mui/icons-material/Campaign";
import ChecklistIcon from "@mui/icons-material/PlaylistAddCheck";
import SearchIcon from "@mui/icons-material/Search";
import NoteIcon from "@mui/icons-material/NoteAdd";
import QuestionIcon from "@mui/icons-material/QuestionMark";
import LogoutIcon from "@mui/icons-material/Logout";
import ReceiptIcon from "@mui/icons-material/Receipt";
import React from "react";
import LandingView from "./LandingView";
import { NavLink as Link, Routes, Route, Navigate } from "react-router-dom";
import EventInfoView from "./EventInfoView";
import DailyTasksView from "./DailyTasksView";
import HowToVideosView from "./HowToVideosView";
import AdminDailyInfo from "./admin/AdminDailyInfo";
import AdminWrapper from "./admin/AdminWrapper";
import EventWrapper from "./admin/EventWrapper";
import AdminEventInfo from "./admin/AdminEventInfo";
import AdminOperationInfo from "./admin/AdminOperationInfo";
import AdminDailyTask from "./admin/AdminDailyTask";
import Chat from "./Chat";
import OrderLookup from "./OrderLookup";
import { observer } from "mobx-react";
import AuthStore from "../stores/AuthStore";
import Schedule from "./Schedule";
import NoteView from "./NoteView";
import PosView from "./PosView";
import GuestListView from "./GuestListView";
import PersonIcon from "@mui/icons-material/Person";
import AdminGuestList from "./admin/AdminGuestList";
import AdminEvents from "./admin/AdminEvents";
import Toaster from "../common/Toaster";
import AdminEvent from "./admin/AdminEvent";
import AdminEventLocations from "./admin/AdminEventLocations";
import RefundView from "./RefundView";
import AdminEventRefunds from "./admin/AdminEventRefunds";
import AdminEventNotes from "./admin/AdminEventNotes";
import RoomCounter from "./RoomCounter";
import MenuIcon from "@mui/icons-material/Menu";
import ImportExportIcon from "@mui/icons-material/ImportExport";

export const Buttons = (): {
    to: string;
    smallIcon: React.ReactNode;
    largeIcon: React.ReactNode;
    title: string;
    disabled?: boolean;
}[] => [
    {
        to: "/schedule",
        smallIcon: <ClockIcon style={{ fontSize: "40px" }} />,
        largeIcon: <ClockIcon style={{ fontSize: "70px", color: "#FF8733" }} />,
        title: "Schedule",
        disabled: !AuthStore.hasAccess("PUNCH_CLOCK_SCHEDULE"),
    },
    {
        to: "/event-info",
        smallIcon: <MegaphoneIcon style={{ fontSize: "40px" }} />,
        largeIcon: <MegaphoneIcon style={{ fontSize: "70px", color: "#FF8733" }} />,
        title: "Event Info",
        disabled: !AuthStore.hasAccess("EVENT_INFO"),
    },
    // {
    //     to: "/daily-tasks",
    //     smallIcon: <ChecklistIcon style={{ fontSize: "40px" }} />,
    //     largeIcon: <ChecklistIcon style={{ fontSize: "70px", color: "#FF8733" }} />,
    //     title: "Daily Tasks",
    // },
    {
        to: "/order-lookup",
        smallIcon: <SearchIcon style={{ fontSize: "40px" }} />,
        largeIcon: <SearchIcon style={{ fontSize: "70px", color: "#FF8733" }} />,
        title: "Order Lookup",
        disabled: !AuthStore.hasAccess("ORDER_LOOKUP"),
    },
    // {
    //     to: "/how-to",
    //     smallIcon: <QuestionIcon style={{ fontSize: "40px" }} />,
    //     largeIcon: <QuestionIcon style={{ fontSize: "70px", color: "#FF8733" }} />,
    //     title: "How to",
    // },
    {
        to: "/guest-list",
        smallIcon: <PersonIcon style={{ fontSize: "40px" }} />,
        largeIcon: <PersonIcon style={{ fontSize: "70px", color: "#FF8733" }} />,
        title: "Guest List",
        disabled: !AuthStore.hasAccess("GUEST_LIST"),
    },
    {
        to: "/refund",
        smallIcon: <ReceiptIcon style={{ fontSize: "40px" }} />,
        largeIcon: <ReceiptIcon style={{ fontSize: "70px", color: "#FF8733" }} />,
        title: "Issue Refund",
        disabled: !AuthStore.hasAccess("ISSUE_REFUND"),
    },
    {
        to: "/room-counter",
        smallIcon: <ImportExportIcon style={{ fontSize: "40px" }} />,
        largeIcon: <ImportExportIcon style={{ fontSize: "70px", color: "#FF8733" }} />,
        title: "Room Counter",
        disabled: !AuthStore.hasAccess("ROOM_COUNTER"),
    },
    {
        to: "/notes",
        smallIcon: <NoteIcon style={{ fontSize: "40px" }} />,
        largeIcon: <NoteIcon style={{ fontSize: "70px", color: "#FF8733" }} />,
        title: "Notes",
        disabled: !AuthStore.hasAccess("NOTES"),
    },
    {
        to: "/admin",
        smallIcon: <SettingsIcon style={{ fontSize: "40px" }} />,
        largeIcon: <SettingsIcon style={{ fontSize: "70px", color: "#FF8733" }} />,
        title: "Setup",
        disabled: !AuthStore.hasAdmin,
    },
];

const LoggedIn = observer(() => {
    const [open, setOpen] = React.useState(false);

    return (
        <>
            <Drawer
                anchor="left"
                variant="temporary"
                open={open}
                onClose={() => {
                    setOpen(false);
                }}
                sx={{
                    width: "70px",
                    height: "100vh",
                    // flexShrink: 0,
                    "& .MuiDrawer-paper": { width: "70px", height: "100vh" },
                }}
            >
                <Box
                    sx={{
                        height: "100%",
                        padding: "20px 5px",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "space-between",
                    }}
                >
                    <Link
                        to="/"
                        onClick={() => {
                            setOpen(false);
                        }}
                    >
                        <Button color="inherit">
                            <FlagIcon style={{ fontSize: "40px" }} />
                        </Button>
                    </Link>
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                        {Buttons()
                            .filter((it) => !it.disabled)
                            .map((it, idx) => (
                                <Link
                                    to={it.disabled ? "/" : it.to}
                                    onClick={() => {
                                        if (!it.disabled) {
                                            setOpen(false);
                                        }
                                    }}
                                >
                                    <Button color="inherit" disabled={it.disabled} sx={idx === Buttons.length - 1 ? {} : { marginBottom: "20px" }}>
                                        {it.smallIcon}
                                    </Button>
                                </Link>
                            ))}
                    </Box>
                    <Button color="inherit">
                        <LogoutIcon
                            style={{ fontSize: "40px" }}
                            onClick={() => {
                                AuthStore.logout();
                                Toaster.info("You have been logged out");
                            }}
                        />
                    </Button>
                </Box>
            </Drawer>
            <Box sx={{ width: "100vw", padding: "10px" }}>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    <IconButton
                        onClick={() => {
                            setOpen(true);
                        }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="body1" sx={{ textAlign: "right", width: "100%" }}>
                        {AuthStore.fullName} ({AuthStore.userId})
                    </Typography>
                </Box>
                <Box sx={{ height: "96%", padding: "40px 20px" }}>
                    <Routes>
                        <Route path="/" element={<LandingView />} />
                        <Route path="/schedule" element={<Schedule />} />
                        <Route path="/event-info" element={<EventInfoView />} />
                        <Route path="/daily-tasks" element={<DailyTasksView />} />
                        <Route path="/order-lookup" element={<OrderLookup />} />
                        <Route path="/how-to" element={<HowToVideosView />} />
                        <Route path="/notes" element={<NoteView />} />
                        <Route path="/pos" element={<PosView />} />
                        <Route path="/guest-list" element={<GuestListView />} />
                        <Route path="/refund" element={<RefundView />} />
                        <Route path="/room-counter" element={<RoomCounter />} />
                        <Route path="/admin" element={<AdminWrapper />}>
                            <Route path="" element={<AdminEvents />} />
                            <Route path="event/:eventId" element={<EventWrapper />}>
                                <Route path="" element={<AdminEvent />} />
                                <Route path="daily-info" element={<AdminDailyInfo />} />
                                <Route path="event-info" element={<AdminEventInfo />} />
                                <Route path="operation-info" element={<AdminOperationInfo />} />
                                <Route path="daily-task" element={<AdminDailyTask />} />
                                <Route path="guest-list" element={<AdminGuestList />} />
                                <Route path="locations" element={<AdminEventLocations />} />
                                <Route path="refunds" element={<AdminEventRefunds />} />
                                <Route path="notes" element={<AdminEventNotes />} />
                            </Route>
                        </Route>
                    </Routes>
                </Box>
            </Box>
            {/* <Box className="notification">
    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "flex-end", height: "100%" }}>
        <span style={{ color: "black", fontWeight: "bold" }}>
            CR: Please remember to print Z-slips and have the seller bring them in when they cashout
        </span>
        <LinearProgress variant="determinate" value={progressValue} />
    </Box>
</Box> */}
            {/* <Chat /> */}
        </>
    );
});

export default LoggedIn;
