import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import React from "react";
import { BACKEND_URI } from "../../constants";
import Fetch from "../../api/Fetch";
import EventStore from "../../stores/EventStore";
import { observer } from "mobx-react";
import { DateTime } from "luxon";

const AdminEventNotes = observer(() => {
    const [loading, setLoading] = React.useState<boolean>(false);
    const [notes, setNotes] = React.useState<any[]>([]);

    const cb = React.useCallback(async () => {
        try {
            const data = await Fetch<any[]>(`${BACKEND_URI}/admin/event/${EventStore.eventId}/notes`, {
                includeAuth: true,
            });
            setNotes(data);
        } catch (err) {
            console.log(err);
        }
    }, []);

    React.useEffect(() => {
        cb();
    }, [cb]);

    return (
        <Box>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Date/Time</TableCell>
                            <TableCell>Title</TableCell>
                            <TableCell>Notes</TableCell>
                            <TableCell>Submitted By</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {notes
                            .sort((a, b) => a.id - b.id)
                            .map((note) => (
                                <TableRow key={note.id}>
                                    <TableCell>{DateTime.fromISO(note.createdAt).toFormat("LLL dd, yyyy h:mm a")}</TableCell>
                                    <TableCell>{note.title}</TableCell>
                                    <TableCell>{note.message}</TableCell>
                                    <TableCell>
                                        {note.user.firstName} {note.user.lastName}
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
});

export default AdminEventNotes;
