import { Paper, Tab, Tabs } from "@mui/material";
import { Box } from "@mui/system";
import { Outlet, useLocation, NavLink as Link, Navigate, useParams } from "react-router-dom";
import AuthStore from "../../stores/AuthStore";
import React from "react";
import EventStore from "../../stores/EventStore";
import { observer } from "mobx-react";

const EventWrapper = observer(() => {
    const location = useLocation();
    const params = useParams<{ eventId: string }>();

    React.useEffect(() => {
        if (!params.eventId) return;
        const eventId = parseInt(params.eventId, 10);
        EventStore.eventOnChange(eventId);
    }, [params.eventId]);

    if (!params.eventId) {
        return <Navigate replace to="/admin/event" />;
    }

    if (!AuthStore.loggedIn || !AuthStore.hasAdmin) {
        return <Navigate replace to="/" />;
    }

    return (
        <Box>
            <Paper sx={{ padding: "10px" }}>
                <Tabs value={location.pathname} variant="scrollable">
                    <Tab label="Event Info" value={`/admin/event/${EventStore.eventId}`} component={Link} to={`/admin/event/${EventStore.eventId}`} />
                    <Tab
                        label="Daily Info"
                        value={`/admin/event/${EventStore.eventId}/daily-info`}
                        component={Link}
                        to={`/admin/event/${EventStore.eventId}/daily-info`}
                    />
                    {/* <Tab
                        label="Daily Tasks"
                        value={`/admin/event/${EventStore.eventId}/daily-task`}
                        component={Link}
                        to={`/admin/event/${EventStore.eventId}/daily-task`}
                    /> */}
                    <Tab
                        label="Guest List"
                        value={`/admin/event/${EventStore.eventId}/guest-list`}
                        component={Link}
                        to={`/admin/event/${EventStore.eventId}/guest-list`}
                    />
                    <Tab
                        label="Locations"
                        value={`/admin/event/${EventStore.eventId}/locations`}
                        component={Link}
                        to={`/admin/event/${EventStore.eventId}/locations`}
                    />
                    <Tab
                        label="Refunds"
                        value={`/admin/event/${EventStore.eventId}/refunds`}
                        component={Link}
                        to={`/admin/event/${EventStore.eventId}/refunds`}
                    />
                    <Tab
                        label="Notes"
                        value={`/admin/event/${EventStore.eventId}/notes`}
                        component={Link}
                        to={`/admin/event/${EventStore.eventId}/notes`}
                    />
                </Tabs>
                <Box sx={{ m: 1 }}>{EventStore.event && <Outlet />}</Box>
            </Paper>
        </Box>
    );
});

export default EventWrapper;
