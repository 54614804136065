import { action, makeAutoObservable } from "mobx";
import { Event } from "../model/Types";
import Fetch from "../api/Fetch";
import { BACKEND_URI } from "../constants";
import Toaster from "../common/Toaster";

class EventStore {
    private _eventId: number | null = null;
    private _event: Event | null = null;
    private _loading = false;

    get event() {
        return this._event;
    }

    get eventId() {
        return this._eventId;
    }

    get loading() {
        return this._loading;
    }

    async eventOnChange(eventId: number) {
        this._event = null;
        this._eventId = eventId;
        this._loading = true;
        try {
            const event = await Fetch<Event>(`${BACKEND_URI}/admin/event/${eventId}`, {
                includeAuth: true,
            });
            this._event = event;
        } catch (err) {
            console.log(err);
        } finally {
            this._loading = false;
        }
    }

    async updateEvent(event: Event) {
        this._loading = true;
        try {
            await Fetch(`${BACKEND_URI}/admin/event`, {
                method: "POST",
                body: event,
                includeAuth: true,
            });
            this._event = event;
            Toaster.success("Event updated");
        } catch (err: any) {
            console.log(err);
            Toaster.error("Error while updating event: " + err.message);
        } finally {
            this._loading = false;
        }
    }

    constructor() {
        makeAutoObservable(this);
    }
}

export default new EventStore();
